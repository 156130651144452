








































































import Vue from 'vue'
// import { NOTES_FETCH, NOTE_ADD, NOTE_DELETE } from '@/store/actions'
// import { mapGetters } from 'vuex'
// import NoteModel from '@/models/NoteModel'

export default Vue.extend({
  name: 'Settings',
  components: {
    // HelloWorld
  },
  mounted () {
    // this.$store.dispatch(NOTES_FETCH, {})
  },
  computed: {
    // ...mapGetters(['notes', 'isLoadingNotes'])
  },
  methods: {
    languageSelected () {
      const selectedLanguage = this.$data.settings.application.currentLanguage
      this.$vuetify.lang.current = selectedLanguage
      this.$i18n.locale = selectedLanguage
      this.$root.$i18n.locale = selectedLanguage
    }
  },
  data: function () {
    return {
      settings: {
        user: {
          nameLabel: this.$t('shared.user.name'),
          name: '',
          emailLabel: this.$t('shared.user.email'),
          email: ''
        },
        application: {
          language: 'shared.language',
          languages: [
            { name: 'shared.languages.german', key: 'de' },
            { name: 'shared.languages.english', key: 'en' }
          ],
          currentLanguage: 'de',
          themes: ['dark', 'light'],
          currentTheme: 'dark'
        }
      }
    }
  }
})
